<template>
  <div class="payments-complete">
    <div class="content" v-show="flag">
      <img src="../../assets/icon/ico-successful.svg" alt="" />
      <p class="text-success">Your payment is successful. Thank you!</p>
      <p class="text-receive">You will receive confirmation email soon.</p>
    </div>
  </div>
</template>
<script>
import { apiSendOwnerRelease } from '../../API/api';
export default {
    data() {
        return {
            flag: false,
        }
    },
    created() {
        apiSendOwnerRelease({lease:this.$route.query.lid}).then(res=>{
           this.flag = true;
        }).catch(()=>{
            this.$alert.error("error")
        })
    },
}
</script>

<style lang="scss" scoped>
.payments-complete {
  width: 100%;
  .content {
    margin: 0 auto;
    margin-top: 287px;
    width: 387px;
    height: 210px;
    .text-success,
    .text-receive {
      font-size: 20px;
      font-family: "Roboto-Regular", sans-serif;
      color: #38425b;
      line-height: 30px;
    }
    .text-success {
      margin-top: 30px;
      font-family: "Roboto-Bold", sans-serif;
    }
  }
}
</style>